@import "~antd/dist/antd.less";
@import "~@a-cloud-guru/rainbow-ui/lib/fonts.less";

.ant-modal-content {
  background: transparent;
  box-shadow: none;
}

.ant-btn-positive {
  &:disabled {
    color: #95A5C2 !important;
    background: #f3f5fa !important;
    border-color: transparent !important;
    text-shadow: none !important;
    box-shadow: none !important;
    path {
      fill: #95A5C2 !important;
    }
  }
}



@primary-color: rgba(66,126,255,1);@error-color: rgba(255,22,117,1);@text-color: rgba(36,50,82,1);@text-color-inverse: rgba(255,255,255,1.0);@body-background: rgba(243,245,250,1);@layout-body-background: rgba(243,245,250,1);@background-color-base: rgba(173,186,208,1);@background-color-light: rgba(247,249,252,1);@border-color-base: rgba(173,186,208,1);@skeleton-color: rgba(243,245,250,1);@disabled-bg: rgba(243,245,250,1);@border-radius-base: 3px;@btn-border-radius-base: 3px;@grid-columns: 24;@btn-font-weight: 500;@screen-xs: 0480px;@screen-sm: 0576px;@screen-md: 0768px;@screen-lg: 0992px;@screen-xl: 1200px;@screen-xxl: 1500px;@btn-shadow: 0;@label-font-weight: 600;@skeleton-border-radius: 99px;@body-background: #fff;@btn-disable-border: transparent;@btn-disable-color: #95A5C2;